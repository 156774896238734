import React, { useState, useEffect } from 'react'
import { FiLoader, FiShoppingCart, FiX } from 'react-icons/fi'
import Button from './UI/Button'
import Price from './UI/Price'
import Title from './UI/Title'

const Option = ({ event, option, options, addProduct, removeProduct, current, setLoad, disabled }) => {
  const [loading, setLoading] = useState(false)

  const currency = option.locale.currency === 'GBP' ? '£' : '€'
  const image = option.stripeCache.product.images.length > 0 ? option.stripeCache.product.images[0] : '/image4.png'
  const product = option
  const upcoming = event.stripeCache.upcoming
  const invoices = [...event.stripeCache.invoices, upcoming]
  const invoice = invoices.find(i => i && i.lines.data.find(l => l.price.product === option.stripeCache.product.id))
  const active = option.active === undefined ? invoice : option.active
  const price = (active && invoice) ? invoice.lines.data.find(l => l.price.product === option.stripeCache.product.id).amount : option.stripeCache.unit_amount

  useEffect(() => {
    if (!current?.loading) setLoading(false)
    setLoad(false)
  }, [current])

  const click = () => {
    if (disabled) return
    if (upcoming && upcoming.loading) return
    if (!active && !current) {
      setLoading(true)
      setLoad(true)
    }
    if (active && !disabled) removeProduct(product)
    else addProduct(product)
  }

  return (
    <div
      className={`flex flex-col border-white bg-gray-100 rounded-xl overflow-hidden text-center justify-start items-center w-full lg:mx-3 my-4 pb-4 lg:my-0 cursor-pointer relative box-border  ${disabled && 'opacity-60'} ${active && ' bg-pink border-pink'}`}
      onClick={click}>
      {!loading &&
        <>
          <div className="w-full bg-cover mb-3 flex-shrink-0 rounded-t-xl" style={{ backgroundImage: `url(${image})`, height: '11rem' }} />
          <Title variant="medium">
            {option.name}
          </Title>
          <div className="flex flex-col items-center justify-evenly h-full mt-2">
            <p className="text-base px-4 mt-1 text-center leading-tight opacity-80" dangerouslySetInnerHTML={{ __html: option.content }} />
          </div>
          <div className="text-lg recoleta font-bold mt-2">
            {price === 0 ? 'OFFERT' : <Price price={price} />}
          </div>
          <Button Icon={active ? FiX : FiShoppingCart} disabled={disabled} className="text-black whitespace-no-wrap">
            {active ? 'Supprimer' : 'Ajouter'}
          </Button>
        </>
      }
      {loading && <FiLoader className="h-8 w-8 stroke-1 rotating box-border inline mt-40" />}
    </div>
  )
}

export default Option
